import React from 'react'
/* import { Link } from 'gatsby' */
/* import { graphql } from 'gatsby' */

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import styles from "./contact.module.css"

const ContactPage = () => (
	<Layout>
	<SEO title="お問い合わせ" />
		<div className={styles.container}>
			<h1>お問い合わせ</h1>
			<p>
			お仕事のご相談・ご依頼、ご不明な点などがございましたら、以下のフォームよりお問い合わせください。
			</p>
			<div className={styles.form}>
				<form name="contact" method="POST" action="/thankyou" data-netlify="true" data-netlify-honeypot="bot-field">
					<input type="hidden" name="form-name" value="contact" />
					<input type="hidden" name="bot-field" />
	
					<label htmlFor="name">お名前</label><span className={styles.required}>(*必須)</span>
					<input type="text" className="form-control" id="name" name="name" required="true" />
	
					<label htmlFor="email">メールアドレス</label><span className={styles.required}>(*必須)</span>
					<input type="email" className="form-control" id="email" name="email" required="true" />
	
					<label htmlFor="content">お問い合わせ内容</label><span className={styles.required}>(*必須)</span>
					<textarea className="form-control" id="content" name="content" rows="8" required="true"></textarea>
	
					<div className={styles.btn_area}>
						<button type="submit" className={styles.submit_btn}>送信</button>
					</div>
				</form>
			</div>
		</div>
	</Layout>
)

export default ContactPage

/* [EOF] */
